;(function(undefined) {
  'use strict';

  String.prototype.formatUnicorn = String.prototype.formatUnicorn ||
    function () {
      var str = this.toString();
      if (arguments.length) {
        var t = typeof arguments[0];
        var key;
        var args = ("string" === t || "number" === t) ?
          Array.prototype.slice.call(arguments)
          : arguments[0];

        for (key in args) {
          str = str.replace(new RegExp("\\{" + key + "\\}", "gi"), args[key]);
        }
      }

      return str;
    };

  var ChematicaForceWorker = function(undefined) {
    'use strict';

    var PPN = 4;
    var PPE = 2;
    var LATEST_CHANGES_LENGTH = 5;

    var Context = {
      // area over which nodes should be spreaded
      area: 1000*1000,
      settings: {
        initialSteps: 10,
        updateSteps: 10
      }
    };

    var NodeMatrix, EdgeMatrix;
    var nodeProperties = { x: 0, y: 1, dx: 2, dy: 3 };
    var edgeProperties = { source: 0, target: 1 };

    function np(i, p) {

      // DEBUG: safeguards
      if ((i % PPN) !== 0)
        throw 'np: non correct (' + i + ').';
      if (i !== parseInt(i))
        throw 'np: non int.';

      if (p in nodeProperties)
        return i + nodeProperties[p];
      else
        throw 'ChematicaForceWorker - ' + 'Inexistant node property given (' + p + ').';
    }

    function ep(i, p) {

      // DEBUG: safeguards
      if ((i % PPE) !== 0)
        throw 'ep: non correct (' + i + ').';
      if (i !== parseInt(i))
        throw 'ep: non int.';

      if (p in edgeProperties)
        return i + edgeProperties[p];
      else
        throw 'ChematicaForceWorkerWorker - ' + 'Inexistant edge property given (' + p + ').';
    }

    function configure(o) {
      function extend() {
        var i, arg, res = {};

        for (i = arguments.length - 1; i >= 0; i--)
          for (arg in arguments[i])
            res[arg] = arguments[i][arg];
        return res;
      }

      Context.settings = extend(o, Context.settings);
    }

    // B.P. Welford (see for ex. Knuth (1998). The Art of Computer Programming, v.2: Seminumerical Algorithms)
    function variance(samples) {
      var length = samples.length;
      if (length < 2) return NaN;

      var x, delta, delta2;
      var n = 0;
      var mean = 0;
      var M2 = 0;
      while (n < length) {
        x = samples[n];
        n += 1;
        delta = x - mean;
        mean += delta / n;
        delta2 = x - mean;
        M2 += delta * delta2;
      }
      return M2 / (n - 1);
    }

    function init(nodes, edges, config) {
      NodeMatrix = nodes;
      EdgeMatrix = edges;
      Context.nodesLength = NodeMatrix.length;
      Context.nodesCount = (Context.nodesLength/PPN);
      Context.edgesLength = EdgeMatrix.length;
      Context.edgesCount = (Context.edgesLength/PPE);
      Context.totalStepsCounter = 0;
      Context.latestChanges = new Array(LATEST_CHANGES_LENGTH).fill(1e3);
      Context.latestChangesIdx = 0;
      Context.latestThresholdChangeStep = 0;
      Context.currentThreshold = 200;
      Context.temperature = 0;
      Context.ready = false;
      Context.isReadyFunction = function(latestChanges, CHANGE_THRESHOLD) {
        var ready = Context.latestChanges.every(function(change) { return change <= CHANGE_THRESHOLD; });
        return ready;
      };
      Context.isPreformatedFunction = function(latestChanges, CHANGE_THRESHOLD) {
        var preformated = Context.latestChanges.every(function(change) { return change <= CHANGE_THRESHOLD; });
        return preformated;
      };

      // Merging configuration
      configure(config || {});
    }

    function step(steps_left, steps_in_batch) {
      // For to small distances use fixed forces to avoid excessive results
      var DISTANCE_LOW_CUTOFF = 1e-2; // FIXME: Should be relative to the actual simulation context

      // Attraction/repulsion balance for two connected nodes at some f(EQUILIBRIUM_CONST) (f is a trivial function)
      var EQUILIBRIUM_CONST = Math.log(1 + Context.nodesCount) * 2 + 2;  // Ranges from ~6 to ~14 for 10 to 1000 nodes
      // For actual setup it is when distance = EQUILIBRIUM_CONST ^ (3/4)
      var EQUILIBRIUM_DISTANCE = Math.pow(EQUILIBRIUM_CONST, 3/4);

      function repulsion(distance) { return EQUILIBRIUM_CONST*EQUILIBRIUM_CONST/(distance*distance); }
      function attraction(distance) { return distance*distance/EQUILIBRIUM_CONST; }

      Context.totalStepsCounter++;

      var n, n1, n2, e, distance, xDistance, yDistance,
          repulsionFactor, xRepulsion, yRepulsion,
          attractionFactor, xAttraction, yAttraction,
          temperature, dx, dy, dispLength, randomDirection;

      for (n = 0; n < Context.nodesLength; n += PPN) {
        NodeMatrix[np(n, 'dx')] = 0;
        NodeMatrix[np(n, 'dy')] = 0;
      }

      var attractionDistancesSum = 0;
      var displacementLengthSum = 0;

      // nodes repulsion
      for (n1 = 0; n1 < Context.nodesLength; n1 += PPN) {
        for (n2 = 0; n2 < n1; n2 += PPN) {

          xDistance = NodeMatrix[np(n1, 'x')] - NodeMatrix[np(n2, 'x')];
          yDistance = NodeMatrix[np(n1, 'y')] - NodeMatrix[np(n2, 'y')];
          distance = Math.sqrt(xDistance*xDistance + yDistance*yDistance);

          if (distance < DISTANCE_LOW_CUTOFF) { // use random push with the force at threshold distance
            randomDirection = Math.random() * Math.PI * 2;
            xDistance = DISTANCE_LOW_CUTOFF * Math.cos(randomDirection);
            yDistance = DISTANCE_LOW_CUTOFF * Math.sin(randomDirection);
            distance = DISTANCE_LOW_CUTOFF;
          }

          repulsionFactor = repulsion(distance)/distance;
          xRepulsion = xDistance * repulsionFactor;
          yRepulsion = yDistance * repulsionFactor;

          NodeMatrix[np(n1, 'dx')] += xRepulsion;
          NodeMatrix[np(n1, 'dy')] += yRepulsion;
          NodeMatrix[np(n2, 'dx')] -= xRepulsion;
          NodeMatrix[np(n2, 'dy')] -= yRepulsion;

        }
      }

      // edges attraction
      for (e = 0; e < Context.edgesLength; e += PPE) {
        n1 = EdgeMatrix[ep(e, 'source')];
        n2 = EdgeMatrix[ep(e, 'target')];

        if (n1 === n2) continue; // ignore self connection edges

        xDistance = NodeMatrix[np(n1, 'x')] - NodeMatrix[np(n2, 'x')];
        yDistance = NodeMatrix[np(n1, 'y')] - NodeMatrix[np(n2, 'y')];
        distance = Math.sqrt(xDistance*xDistance + yDistance*yDistance);

        if (distance < DISTANCE_LOW_CUTOFF) continue;
        attractionDistancesSum += distance;

        attractionFactor = attraction(distance)/distance;
        xAttraction = xDistance * attractionFactor;
        yAttraction = yDistance * attractionFactor;

        NodeMatrix[np(n1, 'dx')] -= xAttraction;
        NodeMatrix[np(n1, 'dy')] -= yAttraction;
        NodeMatrix[np(n2, 'dx')] += xAttraction;
        NodeMatrix[np(n2, 'dy')] += yAttraction;

      }

      // Position adjustment with annealing
      if (Context.totalStepsCounter === 1) {
        Context.temperature = EQUILIBRIUM_DISTANCE * 10;
        Context.currentThreshold = 400;
      }

      var meanEquilibriumRatio = attractionDistancesSum / (Context.edgesCount * EQUILIBRIUM_DISTANCE);
      if (Context.isPreformatedFunction(Context.latestChanges, Context.currentThreshold)) {
        if (Context.temperature > EQUILIBRIUM_DISTANCE) {
          Context.temperature /= 1.6;
        } else if (Context.temperature > EQUILIBRIUM_DISTANCE/2) {
          Context.temperature /= 1.3;
        } else {
          Context.temperature /= 1.04;
        }
        if (Context.currentThreshold > EQUILIBRIUM_DISTANCE/1000) {
          Context.currentThreshold /= 1.15;
          Context.latestThresholdChangeStep = Context.totalStepsCounter;
        }
      }

      temperature = Context.temperature;

      for (n = 0; n < Context.nodesLength; n += PPN) {

        dx = NodeMatrix[np(n, 'dx')];
        dy = NodeMatrix[np(n, 'dy')];
        dispLength = Math.sqrt(dx*dx + dy*dy);

        displacementLengthSum += dispLength;

        if (dispLength < temperature) {

          NodeMatrix[np(n, 'x')] += dx;
          NodeMatrix[np(n, 'y')] += dy;

          // label = "{idx}: ({x}, {y}, {dx}, {dy})".formatUnicorn(
          //    idx: n/PPN,
          //    x: NodeMatrix[np(n, 'x')].toFixed(0), y: NodeMatrix[np(n, 'y')].toFixed(0),
          //    dx: dx.toFixed(0), dy: dy.toFixed(0)});

        } else {

          NodeMatrix[np(n, 'x')] += dx * temperature / dispLength;
          NodeMatrix[np(n, 'y')] += dy * temperature / dispLength;

          // label = "{idx}: ({x}, {y}, !{dx}, !{dy})".formatUnicorn({
          //   idx: n/PPN,
          //   x: NodeMatrix[np(n, 'x')].toFixed(0), y: NodeMatrix[np(n, 'y')].toFixed(0),
          //   dx: (dx * temperature / dispLength).toFixed(0), dy: (dy * temperature / dispLength).toFixed(0) });
        }
      }


      var meanDisplacement = displacementLengthSum/Context.nodesCount;
      var relativeChange = meanDisplacement/EQUILIBRIUM_DISTANCE;

      Context.latestChanges[Context.latestChangesIdx++] = relativeChange;
      Context.latestChangesIdx %= LATEST_CHANGES_LENGTH;

      var isReadyByChangesThreshold = Context.isReadyFunction(Context.latestChanges, 0.03);
      var isReadyByStepsLimit = (Context.totalStepsCounter - Context.latestThresholdChangeStep) > 200;
      var isReady = isReadyByChangesThreshold || isReadyByStepsLimit;
      Context.ready = isReady;
    }

    function sendResult() {
      self.postMessage(
        {nodes: NodeMatrix.buffer, ready: Context.ready},
        [NodeMatrix.buffer]
      );
    }

    function run(n) {
      for (var i = 0; i < n && !Context.ready; i++) {
        step(n - i, n);
      }

      sendResult();
    }

    // On supervisor message
    var listener = function(e) {
      switch (e.data.action) {
        case 'start':
          init(
            new Float32Array(e.data.nodes),
            new Float32Array(e.data.edges),
            e.data.config
          );

          // First iteration(s)
          Context.ready = false;
          run(Context.settings.initialSteps);
          break;

        case 'loop':
          NodeMatrix = new Float32Array(e.data.nodes);
          run(Context.settings.updateSteps);
          break;

        case 'config':
          configure(e.data.config);
          break;

        case 'kill':
          // Deleting Context for garbage collection
          Context.keys().filter(function (prop) { return Context.hasOwnProperty(prop);}).forEach(delete Context[prop]);
          NodeMatrix = null;
          EdgeMatrix = null;
          self.removeEventListener('message', listener);
          break;

        default:
      }
    };

    // connect to parent thread
    self.addEventListener('message', listener);
  };


  function getWorkerCode() {

    function crush(fnString) {
      var pattern, i, l;

      var np = ['x','y','dx','dy'];
      var ep = ['source','target'];

      // replace matrix accessors by incremented indexes
      for (i = 0, l = np.length; i < l; i++) {
        pattern = new RegExp('np\\(([^,]*), \'' + np[i] + '\'\\)', 'g');
        fnString = fnString.replace(pattern, (i === 0) ? '$1' : '$1 + ' + i);
      }
      for (i = 0, l = ep.length; i < l; i++) {
        pattern = new RegExp('ep\\(([^,]*), \'' + ep[i] + '\'\\)', 'g');
        fnString = fnString.replace(pattern, (i === 0) ? '$1' : '$1 + ' + i);
      }

      return fnString;
    }

    return ';(' + crush(ChematicaForceWorker.toString()) + ').call(this);';
  }
  if (!('document' in this)) {

    // If the code is used in a webworker lets execute it
    eval(getWorkerCode());

  } else {

    // Assuming the code is added to the sigma package we provide an access to it for future reference.
    if (typeof sigma === 'undefined')
      throw 'sigma is not declared';

    sigma.prototype.getChematicaForceWorker = getWorkerCode;
  }
}).call(this);
