;(function(undefined) {
  'use strict';

  if (typeof sigma === 'undefined')
    throw 'sigma is not declared';

  if (!('Worker' in this))
    throw 'Web worker support required';

  var _root = this;

  function ChematicaForceSupervisor(sigmaInstance, options) {

    var self = this;

    var workerFn = sigmaInstance.getChematicaForceWorker && sigmaInstance.getChematicaForceWorker();

    options = options || {};

    // _root URL Polyfill
    _root.URL = _root.URL || _root.webkitURL;

    this.PPN = 4;
    this.PPE = 2;
    this.sigmaInstance = sigmaInstance;
    this.graph = this.sigmaInstance.graph;
    this.config = {};

    // State
    this.started = false;
    this.running = false;

    var blob = this.makeBlob(workerFn);
    this.worker = new Worker(URL.createObjectURL(blob));

    // Post Message Polyfill
    this.worker.postMessage = this.worker.webkitPostMessage || this.worker.postMessage;

    // Worker message receiver
    this.workerListener = function(e) {

      // The only message sent contains reacalculated nodes positions
      self.nodesByteArray = new Float32Array(e.data.nodes);

      if (self.running) {

        self.applyLayoutChanges();

        // Send data back to worker and loop if not ready
        if (!e.data.ready) {

          self.sendLoopArraysToWorker();
          self.sigmaInstance.refresh();

        } else {

          self.sigmaInstance.refresh();
          self.stop();

        }
      }
    };

    this.worker.addEventListener('message', this.workerListener);

    this.graphToByteArrays();

    // Bind to on kill to properly terminate layout when parent is killed
    sigmaInstance.bind('kill', function() {
      sigmaInstance.killChematicaForce();
    });
  }

  ChematicaForceSupervisor.prototype.makeBlob = function(workerFn) {
    try {
      return new Blob([workerFn], {type: 'application/javascript'});
    } catch (e) {
      _root.BlobBuilder = _root.BlobBuilder || _root.WebKitBlobBuilder || _root.MozBlobBuilder;

      var blob = new BlobBuilder();
      blob.append(workerFn);
      return blob.getBlob();
    }
  };

  ChematicaForceSupervisor.prototype.graphToByteArrays = function() {
    var nodes = this.graph.nodes(),
        edges = this.graph.edges(),
        nodesIndex = {},
        i, j, l;

    this.nodesByteArray = new Float32Array(nodes.length * this.PPN);
    this.edgesByteArray = new Float32Array(edges.length * this.PPE);

    for (i = j = 0, l = nodes.length; i < l; j += this.PPN, i++) {

      nodesIndex[nodes[i].id] = j; // will use it below with edges

      this.nodesByteArray[j] = nodes[i].x;
      this.nodesByteArray[j + 1] = nodes[i].y;
      // dx,dy are zeroed in worker
      // this.nodesByteArray[j + 2] = 0;
      // this.nodesByteArray[j + 3] = 0;
      // Additiona data for more sophisticated forces calculation?
      // this.nodesByteArray[j + 5] = 1 + this.graph.degree(nodes[i].id);
      // this.nodesByteArray[j + 6] = nodes[i].size;
    }

    // Iterate through edges
    for (i = j = 0, l = edges.length; i < l; j += this.PPE, i++) {
      this.edgesByteArray[j] = nodesIndex[edges[i].source];
      this.edgesByteArray[j + 1] = nodesIndex[edges[i].target];
    }
  };

  ChematicaForceSupervisor.prototype.applyLayoutChanges = function() {
    var nodes = this.graph.nodes();

    for (var i = 0, j = 0, l = this.nodesByteArray.length; i < l; i += this.PPN, j++) {
      nodes[j].x = this.nodesByteArray[i];
      nodes[j].y = this.nodesByteArray[i + 1];
    }
  };

  ChematicaForceSupervisor.prototype.sendInitialArraysToWorker = function() {
    this.worker.postMessage({
        action: 'start',
        config: this.config || {},
        nodes: this.nodesByteArray.buffer,
        edges: this.edgesByteArray.buffer
      },
      [this.nodesByteArray.buffer, this.edgesByteArray.buffer]
    );
  };

  ChematicaForceSupervisor.prototype.sendLoopArraysToWorker = function() {
    if (this.nodesByteArray.length) {
      this.worker.postMessage({
          action: 'loop',
          nodes: this.nodesByteArray.buffer
        },
        [this.nodesByteArray.buffer]
      );
    }
  };

  ChematicaForceSupervisor.prototype.start = function() {
    if (this.running)
      return;

    this.running = true;

    // Do not refresh edgequadtree during layout:
    var k, c;
    for (k in this.sigmaInstance.cameras) {
      c = this.sigmaInstance.cameras[k];
      c.edgequadtree._enabled = false;
    }

    if (!this.started) {
      this.sendInitialArraysToWorker();
      this.started = true;
    }
    else {
      this.sendLoopArraysToWorker();
    }
  };

  ChematicaForceSupervisor.prototype.stop = function() {
    if (!this.running)
      return;

    // Reenable edgequadtree refresh:
    var k, c, bounds;

    for (k in this.sigmaInstance.cameras) {
      c = this.sigmaInstance.cameras[k];
      c.edgequadtree._enabled = true;

      // Find graph boundaries:
      bounds = sigma.utils.getBoundaries(this.graph, c.readPrefix);

      // Refresh edgequadtree:
      if (c.settings('drawEdges') && c.settings('enableEdgeHovering'))
        c.edgequadtree.index(this.sigmaInstance.graph, {
          prefix: c.readPrefix,
          bounds: {
            x: bounds.minX,
            y: bounds.minY,
            width: bounds.maxX - bounds.minX,
            height: bounds.maxY - bounds.minY
          }
        });
    }

    this.running = false;
  };

  ChematicaForceSupervisor.prototype.killWorker = function() {
    this.worker.terminate();
  };

  ChematicaForceSupervisor.prototype.configure = function(config) {
    this.config = config;

    if (!this.started)
      return;

    this.worker.postMessage({action: 'config', config: this.config});
  };

  sigma.prototype.startChematicaForce = function(config) {

    if (!this.chForceSupervisor)
      this.chForceSupervisor = new ChematicaForceSupervisor(this, config);

    if (config)
      this.chForceSupervisor.configure(config);

    this.chForceSupervisor.start();

    return this;
  };

  sigma.prototype.stopChematicaForce = function() {
    if (!this.chForceSupervisor)
      return this;

    this.chForceSupervisor.stop();

    return this;
  };

  sigma.prototype.killChematicaForce = function() {
    if (!this.chForceSupervisor)
      return this;

    this.chForceSupervisor.stop();
    this.chForceSupervisor.killWorker();
    this.chForceSupervisor = null;

    return this;
  };

  sigma.prototype.configChematicaForce = function(config) {
    if (!this.chForceSupervisor)
      this.chForceSupervisor = new ChematicaForceSupervisor(this, config);

    this.chForceSupervisor.configure(config);

    return this;
  };

  sigma.prototype.isChematicaForceRunning = function() {
    return !!this.chForceSupervisor && this.chForceSupervisor.running;
  };
}).call(this);
