var ketcherFrame;
var ketcher = null;
var pastedText = undefined;
var isPasted = false;
var isMainKetcherEditor = false;

function getKetcherEditor(ketcherTemplateId) {
  if (ketcherTemplateId === 'ketcher') {
    isMainKetcherEditor = true;
    ketcherFrame = document.getElementById('ifKetcher');
    if (ketcherFrame) {
      ketcherFrame.onload = function () {
        addPasteNotification();
        addMouseUpEvent();
      };
      addPasteNotification();
      addMouseUpEvent();
    }
  } else {
    isMainKetcherEditor = false;
    ketcherFrame = document.getElementById('ifKetcher2');
    if (ketcherFrame) {
      ketcherFrame.onload = function () {
        addMouseUpEvent();
        addPasteNotification();
      };
      addMouseUpEvent();
      addPasteNotification();
    }
  }
  if (ketcherFrame) {
    if ('contentDocument' in ketcherFrame)
      ketcher = ketcherFrame.contentWindow.ketcher;
    else // IE7
      ketcher = document.frames['ifKetcher'].window.ketcher;
  }
  if (!!ketcher) {
    try {
      ketcher?.editor.setOptions('{"showStereoFlags": false, "dearomatize-on-load": true}');
    } catch {}
  }
  return ketcher;
}

function addMouseUpEvent() {
  if (isMainKetcherEditor) {
    ketcherFrame.contentDocument.body.removeEventListener('keyup', sendValueToField);
    ketcherFrame.contentDocument.body.removeEventListener('mouseup', sendValueToField);
    ketcherFrame.contentDocument.body.addEventListener('mouseup', (event) => {
      this.sendValueToField(event);
    });
    ketcherFrame.contentDocument.body.addEventListener('keyup', (event) => {
      if (!isPasted)
        this.sendValueToField(event);
    });
  } else {
    ketcherFrame.contentDocument.body.removeEventListener('keyup', addSmilesToEditor);
    ketcherFrame.contentDocument.body.removeEventListener('mouseup', addSmilesToEditor);
    ketcherFrame.contentDocument.body.addEventListener('mouseup', (event) => {
      this.addSmilesToEditor(event);
    });
    ketcherFrame.contentDocument.body.addEventListener('keyup', (event) => {
      if (!isPasted)
        this.addSmilesToEditor(event);
    });
  }
}

function sendValueToField(value) {
  if (isPasted) {
    isPasted = false;
    var element = document.getElementById('hidden-input');
    element.value = pastedText;
    pastedText = '';
    element.dispatchEvent(new Event('input', {
      bubbles: true
    }));
  }
}

function addPasteNotification() {
  ketcherFrame.contentWindow.removeEventListener('paste', handlePasteEvent);
  ketcherFrame.contentWindow.addEventListener('paste', handlePasteEvent);
}

function handlePasteEvent(event) {
  isPasted = true;
  if (window.clipboardData && window.clipboardData.getData) { // IE
    pastedText = window.clipboardData.getData('Text');
  } else if (event.clipboardData && event.clipboardData.getData) {
    pastedText = event.clipboardData.getData('text/plain');
  }
  return false;
}

function addSmilesToEditor() {
  if (isPasted) {
    isPasted = false;
    var element = document.getElementById('hidden-input-editor');
    element.value = pastedText;
    element.dispatchEvent(new Event('input', {
      bubbles: true
    }));
  }
}
